<template>
  <FocusTrap>
    <div id="mycard" class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Bale's List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="card-body" style="padding: 0px;">
      <div class="table-responsive">
        <table id="mytable"
               class="table  table-hover  table-bordered table-condensed table-columned"
               data-search="false"
               data-pagination="true"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-unique-id="id"
               data-page-size="All"
               data-detail-view="true"
               data-detail-formatter="baleDetailFormatter"
               data-target=".context-table">
          <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-field="id" data-class="d-none" data-sortable="true">ID</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="bale_no" data-width="100" data-sortable="true">Bale No</th>
              <th data-field="status" data-width="100" data-sortable="true">Status</th>
              <th data-field="doc_date" data-width="120" data-formatter="dateFormatter" data-sortable="true">Date</th>
              <th data-field="order.buyer.name" data-sortable="true">Buyer</th>
              <th data-field="order.doc_no" data-sortable="true">Order No</th>
              <th data-field="order.agent.name" data-sortable="true">Agent</th>
              <th data-field="quality.name" data-sortable="true">Quality</th>
              <th data-field="pcs" data-width="100" data-sortable="true" data-align="right">Pcs</th>
              <th data-field="qty" data-formatter="indianFormat4Value" data-width="100" data-sortable="true" data-align="right">Mtr</th>
              <th data-field="weight" data-formatter="weightFormat" data-width="125" data-sortable="true" data-align="right">Weight</th>
<!--              <th data-field="remarks" data-sortable="true">Remarks</th>-->
            </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

    </div>

    <!--    <div id="bale_tabulator"></div>-->

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-printer2" style="color: #f30824"></i>Print</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <FocusTrap>
      <vmodal name="bale_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="75%" @closed="afterCloseBaleWindow" >
        <BaleForm v-if="voucher.id > 0" v-bind:myvoucher="voucher" v-on:bale_window_closed="closeModal" v-on:bale_saved="loadData" />
      </vmodal>
    </FocusTrap>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap v-model:active="isActive">
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Quality:</label>
                  <div class="col-md-9">
                    <select id="cmbquality" class="form-control" v-model="quality_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in qualities" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>

<!--                <div class="form-group row">-->
<!--                  <label class="col-md-3 col-form-label">Color:</label>-->
<!--                  <div class="col-md-9">-->
<!--                    <select class="form-control" v-model="color_id" >-->
<!--                      <option value="0" selected>All</option>-->
<!--                      <option v-for="camp in colors" v-bind:value="camp.id">-->
<!--                        {{ camp.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

      <!-- modal -->
      <div id="fsPrintPreview"
           class="modal animated bounceIn"
           tabindex="-1"
           role="dialog"
           aria-labelledby="myModalLabel"
           aria-hidden="true">

        <!-- dialog -->
        <div class="modal-dialog modal-full">

          <!-- content -->
          <div class="modal-content" style="padding: 0px;">

            <!-- body -->
            <div class="modal-body">
              <ReportViewer v-if="canprint" v-bind:reportfilename="filename" v-bind:json_data = jsondata v-bind:report_url="reppath"></ReportViewer>
            </div>
            <!-- body -->

          </div>
          <!-- content -->

        </div>
        <!-- dialog -->

      </div>
      <!-- modal -->


    </div>
  </FocusTrap>
</template>

<script>
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import BaleForm from './BaleForm'
// import ReportViewer from "@/views/tools/ReportViewer";
import ReportViewer from "@/views/tools/ReportViewerByJson";

export default {
  name: 'BaleView',
  store,
  components: {
    BaleForm,
    ReportViewer,
    FabButton,
    moment
  },
  data () {
    return {
      isActive: false,
      voucher: JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}'),
      mytable: {},
      from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      quality_id : 0,
      qualities : [],
      color_id : 0,
      colors : [],
      reppath: '',
      jsondata : {},
      filename: 'bale_document.mrt',
      canprint: false,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();

    $('[data-toggle="popover"]').popover();
    $('#mytable').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {

        let id = $(row.children('*')[0]).text();
        let code = $(row.children('*')[1]).text();

        if ($(e.target).text() === 'Refresh') {
          self.loadData();
        } else if ($(e.target).text() === 'Print') {
          self.printPreview(code);
        } else if ($(e.target).text() === 'Modify') {
          self.modifyDocument(code);
        } else if ($(e.target).text() == 'Remove') {
          self.removeDocument(code);
        }
      }
    });

    self.canprint = false;

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#mytable>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

    $('[data-action=remove]').click(function (e) {
      //$('#bale_window').modal('hide');
    })

    $('#fsPrintPreview').on('hidden.bs.modal', function (e) {
      self.isActive = false;
      self.reppath = '';
    });

    this.loadQualities();
    this.showFilterWindow();
  },
  methods: {
    afterfilterwindow_visible(){
      $('#txtfrom').focus();
    },
    showFilterWindow(){
      this.$data.isActive = true;
      this.$modal.show('filter-window');
    },
    closeModal () {
      this.$data.isActive = false;
      this.$modal.hide('bale_window');
    },
    afterCloseBaleWindow(){
      this.voucher = JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
    },
    showModal () {
      //$('#bale_window').modal('hide')
    },
    clear () {
      this.voucher = JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = {"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]};
      fetch(`${process.env.VUE_APP_ROOT_API}api/bale/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {

            self.$data.voucher = resp.data;

            self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

            self.$modal.show('bale_window');
            //$('#bale_window').modal('show')
          }
        } else {
          swal({ title: 'Oh noes!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      });
    },
    removeDocument (id) {
      const self = this

      self.$data.voucher.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete this!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/bale`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('bale_window')
            self.loadData()
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error', timer:3000 })
          })
        }
      })

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');
      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$modal.hide('filter-window');
      self.$modal.hide('bale_window');
      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      let filters = `from=${self.from_date}&upto=${self.upto_date}&quality_id=${self.quality_id}&color_id=${self.color_id}`;

      fetch(`${process.env.VUE_APP_ROOT_API}api/bales?${filters}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $("#mycard").unblock()
      });
    },
    printPreview (id) {
      const self = this;

      self.isActive = false;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      // console.log(`${process.env.VUE_APP_ROOT_API}api/bale/print/${id}`);
      self.canprint = false;
      self.$data.voucher =  JSON.stringify('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/bale/print/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok) {
          if (!_.isNull(resp.data)) {

            self.$data.jsondata = resp;

            if(resp.data.itemtype == 2) {
              self.$data.filename = 'bale_document_2.mrt';
            }
            self.$data.reppath =  `${process.env.VUE_APP_ROOT_API}api/bale/print/${id}`;
            $('#fsPrintPreview').modal('show');
            self.canprint = true;

          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      });
      // self.$data.filename = 'bale_document.mrt';

    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.qualities =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
  }
}
</script>

<style scoped>
  .modal-full {
    min-width: 100%;
    margin: 0;
  }

  .modal-full .modal-content {
    min-height: 100vh;
  }

  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
